body,
form,
li,
ul,
table,
tr,
td,
th,
button,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
input,
label,
div,
fieldset {
  cursor: pointer;
  font-family: "M PLUS Rounded 1c", sans-serif;
}

header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed {
  background-color: #ffffff;
}

a.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineHover {
  color: #212b36;
}

/* svg.MuiSvgIcon-root, .MuiAvatar-root.MuiAvatar-circular, .MuiAvatar-colorDefault {
  background-color: #d5c4c412 !important;
}
*/
.header {
  box-shadow: 0px 2px 4px -1px rgb(145 158 171 / 20%), 0px 4px 5px 0px rgb(145 158 171 / 0%), 0px 1px 10px 0px rgb(145 158 171 / 0%) !important;
}

.dashboard_header {
  box-shadow: 0px 2px 4px -1px rgb(145 158 171 / 20%), 0px 4px 5px 0px rgb(145 158 171 / 0%), 0px 1px 10px 0px rgb(145 158 171 / 0%) !important;
}

.footer_Icon {
  display: flex !important;
  justify-content: flex-start !important;
}

iframe {
  z-index: -1 !important;
}

.Provider_Heading .MuiTypography-h6 {
  margin-top: 30px;
  font-size: 40px;
  text-align: left;
}

.provider_list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

/* .provider_list .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  margin-bottom: 30px;
  width: 31%;
}
*/
.dashboard_main .MuiContainer-root.MuiContainer-maxWidthLg {
  width: 100% !important;
  max-width: 100% !important;
}

.profile_data {
  text-align: left;
  padding-left: 30px !important;
}

.error {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  line-height: 1.5714285714285714;
  font-size: 0.875rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 400;
  background-color: rgb(255, 245, 243);
  display: flex;
  padding: 0px 16px;
  color: rgb(102, 65, 56);
  color: #7a0c2e;
  background-color: #ffe7d9;
}

tr:nth-child(even) {
  background: #f6f7f8;
}

tr:nth-child(odd) {
  background: #fff;
}

table,
tbody,
thead,
tr,
th,
th {
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0px auto;
}

/* .provider_img {
  max-height: 170px;
}
*/
/* ::-webkit-scrollbar, .simplebar-content-wrapper::-webkit-scrollbar {
  width: 50px;
}
*/
::-webkit-scrollbar-track,
.simplebar-content-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #6495ed;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb,
.simplebar-content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar,
.simplebar-content-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background-color: #6495ed;
  /* or add it to the track */
  margin-top: 10px;
}

.title {
  content: "";
  position: absolute;
  /* top: 100%;
  */
  width: 15%;
  /* left: 0;
  */
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(130deg, #ce1b76, #ffffff 41.07%, #6495ed 76.05%);
}

.Provider_Heading span:before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(130deg, #ff7a18, #af002d 41.07%, #319197 76.05%);
}

.patient_provider_img span.MuiBox-root {
  padding-top: calc(30% / 16 * 9);
}

label.form-check-label {
  margin: 10px;
}

.input {
  width: 100%;
  height: 40px;
  border-radius: 5px 5px;
  padding: 10px;
  margin: 10px;
}

input#flexCheckDefault {
  margin: 20px 0px;
}

.slick-slide p.MuiTypography-root {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.MuiButton-containedSuccess {
  color: #ffffff;
}

.odd_even:nth-child(even) .mycard {
  background-color: #f6f7f8;
}

.odd_even:nth-child(even) {
  background-color: #f6f7f8;
}

.odd_even:nth-child(odd) .mycard {
  background-color: #ffffff;
}

input.Services {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

/* .MuiPopover-root .Mui-selected {
  background-image: url('../Image/true_icon.png');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  height: 25px;
}
*/
div#demo-multiple-name {
  height: auto !important;
}

.MuiInputLabel-asterisk {
  color: #ce1b76;
}

.appoinment span.MuiBadge-badge.MuiBadge-standard {
  width: 30px;
  height: 30px;
  color: #fff;
  font-weight: bolder;
  margin-left: 20px;
}

.paypal-buttons.paypal-buttons-context-iframe iframe {
  position: unset !important;
}

.service_number {
  -moz-appearance: textfield;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 7px 10px;
  padding-left: 0;
  border: 1px solid;
  border-radius: 3px;
}

input.service_checkbox {
  margin: 12px 0px !important;
}

.service_checkbox_div {
  margin: 5px 0px;
}

.menu_button button {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.menu_button {
  align-items: center;
}

.menu_button .MuiTabs-scroller {
  margin-right: 20px;
}

.menu_button .MuiTabs-scrollButtons {
  border: 2px solid #d9d0d0;
  color: black;
  width: 30px;
  height: 30px;
}

.account-button:hover {
  background: transparent;
}

.account-button {
  background: transparent;
}

.provider_list {
  justify-content: center;
}

/* .MuiAvatar-root.MuiAvatar-circular {
  color: #fff !important;
}
*/
.MuiDrawer-docked .MuiListItemIcon-root {
  color: #6495ed !important;
}

.MuiTabs-hideScrollbar .Mui-selected {
  color: #6495ed !important;
  padding: 10px 0px;
}

.MuiRating-icon svg.MuiSvgIcon-root {
  padding-top: 2px;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorPrimary {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  color: #6495ed;
}

div#notistack-snackbar {
  text-transform: capitalize;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  min-width: 200px !important;
  /* max-width: 300px;
  */
  max-height: 80vh;
}

.year-picker {
  width: 100% !important;
  font-size: 20px !important;
  height: 50px !important;
}

.picker-panel.popup-left {
  z-index: 9999;
}

.head_menu_button {
  padding: 10px;
  text-decoration: none;
}

a.head_menu_button:hover {
  color: #637388;
}

/* .SpecialtyBoardCertification div,.SubSpecialtyBoardCertification div {
  color: #ffff;
}
*/
/* .empty_element :first-child {
  display: none;
}
*/
.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault {
  color: #ffff;
}

/* .MuiToolbar-gutters button {
  display: none;
}
*/
.All_provider div {
  overflow: hidden;
}

.practicing_since .MuiOutlinedInput-notchedOutline {
  border-color: #dce0e4 !important;
}

.practicing_since .MuiFormControl-root {
  width: 100%;
}

.uplaoder_retangle>div {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 5%;
  padding: 0px;
  border: 5px solid #f4f6f8;
}

.uplaoder_retangle img {
  width: 100%;
  /* height: 195px;
  */
  border-radius: 15px;
}

.uplaoder_retangle .css-soonpg {
  z-index: 0;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 300px;
  outline: none;
  display: flex;
  overflow: hidden;
  border-radius: 5%;
  position: relative;
  align-items: center;
  justify-content: center;
}

header .Mui-disabled {
  display: none;
}

header .MuiTabs-scroller button:first-child {
  margin-right: 20px;
}

.hide_Pro_text .MuiDataGrid-main div:nth-child(3) {
  /* display: none;
  */
  /* color: transparent;
  */
}

.MuiDataGrid-pinnedColumns.MuiDataGrid-pinnedColumns--left {
  min-height: auto !important;
}

.uplaoder_retangle span.text {
  position: absolute;
  top: 45%;
  left: 0;
  color: #f6f7f8;
  margin: 0px auto;
  right: 0;
}

a .MuiListItemText-root {
  text-overflow: ellipsis;
  overflow: hidden;
}

.header_heading {
  margin: 20px 0px;
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.MuiDataGrid-columnHeaderTitle {
  color: #6495ed;
  text-transform: capitalize !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

/* .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell .MuiDataGrid-cellContent {
  counter-reset: Serial;
}
*/
/* .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell .MuiDataGrid-cellContent {
  border-collapse: separate;
}
*/
/* .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell .MuiDataGrid-cellContent:first-child:before {
  counter-increment: Serial;
  content: counter(Serial);
}
*/
body {
  counter-reset: Serial;
  /* Set the Serial counter to 0 */
}

.MuiDataGrid-virtualScrollerContent {
  border-collapse: separate;
}

.MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:first-child:before {
  counter-increment: Serial;
  /* Increment the Serial counter */
  content: counter(Serial);
  /* Display the counter */
}

.hide_button {
  display: none;
}

ul.MuiList-root.MuiList-padding.MuiDataGrid-menuList.css-h4y409-MuiList-root {
  background-color: #6495ed;
  color: #ffff;
  border-radius: 10px;
}

li.ActionsCellItem div {
  min-width: 10px !important;
}

.MuiDataGrid-actionsCell svg {
  color: #6495ed;
}

.DONE,
.ACTIVE {
  border-radius: 10px;
  background-color: #229a16;
  padding: 5px;
  color: #ffff;
}

.PENDING {
  border-radius: 10px;
  background-color: #ffc107;
  padding: 5px;
  color: #ffff;
}

.EXPIRED {
  border-radius: 10px;
  background-color: #0c53b7;
  padding: 5px;
  color: #ffff;
}

.CANCELED,
.INACTIVE {
  border-radius: 10px;
  background-color: red;
  padding: 5px;
  color: #ffff;
}

.ACCEPTED {
  border-radius: 10px;
  background-color: #1890ff;
  padding: 5px;
  color: #ffff;
}

li.ActionsCellItem {
  border: 1px solid #ffffff;
  margin: 10px;
  border-radius: 10px;
}

.payment li {
  border: 1px solid #6495ed;
  color: #6495ed;
}

.MuiDataGrid-row:nth-child(2n) {
  background: #f6f7f8;
}

.practicing_since label,
.practicing_since input {
  color: #919eab !important;
}

.MuiDataGrid-row .MuiChip-root {
  border-radius: 10px;
  padding: 0px;
  height: 23px;
  font-size: 0.75rem;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-weight: 700;
}

.MuiDataGrid-row .MuiChip-ghostSuccess {
  color: #229A16;
  background-color: rgba(84, 214, 44, 0.16);
}

.MuiDataGrid-row .MuiChip-ghostError {
  color: #B72136;
  background-color: rgba(206, 27, 118, 0.16);
}

.messages_list {
  overflow-wrap: break-word;
}

.messages_list:first-child {
  display: flex;
  justify-content: center;
}

.messages_list:first-child img {
  display: none;
}

.patient_rating div span {
  font-size: 23px;
}

td span {
  cursor: -webkit-grab !important;
  cursor: grab !important;
}

.MuiDrawer-paperAnchorRight {
  align-items: flex-start;
  padding-left: 20px;
}

/* a:hover {
  background: radial-gradient(#e66465, #9198e5);
} */

[aria-label="Filter list"] {
  display: none;
}

.CloseIcon {

  right: 5px;
  position: absolute;
  top: 5px;
  background-color: darkgray;
  border-radius: 50%;
  z-index: 1;
}

.loading img {
  width: 100%;
  height: 100%;
}

.schedule_form {
  overflow-x: scroll;
  padding-right: 20px;
}