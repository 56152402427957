@media (min-width: 1200px) {
    .MuiContainer-root.MuiContainer-maxWidthLg {
        max-width: 95%;
    }
}

@media (max-width: 880px) {
    .head_menu {
        display: none;
    }

    .search_box span {
        text-overflow: ellipsis !important;
        white-space: nowrap;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        white-space: pre-wrap;
    }

    .scroll_menu_tab {
        max-width: 80%;
    }

    .HomeFooter li {
        display: none;
    }

    .HomeFooter div span {
        margin: 0px !important;
    }

    .HomeFooter .footer_arrow {
        margin: -8px 0px !important;
    }
}

@media (max-width: 640px) {
    .head_menu_logo {
        display: none;
    }

    .scroll_menu_tab {
        max-width: 65%;
    }

}

@media (max-width:320px) {
    .HomeFooter div span {

        display: none;
    }

    .HomeFooter div button svg {
        margin-left: 100px;
    }
}

@media (max-width: 980px) {
    .HomeFooter div span {
        margin: 0px !important;
    }
    .HomeFooter li {
        display: none;
    }
}